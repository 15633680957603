footer {
  background-image: url("../../assets/img/footer-bg.png") !important;
  /* height: 600px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .card-semi-transparent {
    background-color: #fff1 !important;
  }

  .footer-link-list {
    font-size: 13px;
  }
}
