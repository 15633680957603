li.bullet-title-description {
  h3 {
    &:not(.no-left-border) {
      padding-left: 5px;

      &.light-blue-theme {
        border-left: 5px solid #427ffa !important;
      }

      &.navy-theme {
        border-left: 5px solid #020d88 !important;
      }

      &.orange-theme {
        border-left: 5px solid #fd403f !important;
      }

      &.green-theme {
        border-left: 5px solid #00a17f !important;
      }

      &.cyan-theme {
        border-left: 5px solid #1899d7 !important;
      }

      &.red-theme {
        border-left: 5px solid #e2626b !important;
      }

      &.pink-theme {
        border-left: 5px solid #d16b86 !important;
      }

      &.purple-theme {
        border-left: 5px solid #747ed1 !important;
      }

      &.brown-theme {
        border-left: 5px solid #4c1036 !important;
      }

      &.violet-theme {
        border-left: 5px solid #a07cc5 !important;
      }

      &.yellow-theme {
        border-left: 5px solid #fab758 !important;
      }

      // &.xxxxxx-theme {
      //   border-left: 5px solid #xxxxxxx !important;
      // }
    }
  }
}
