.payment-links-page {
  .checklist-icon {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .bank-card-icon {
    width: 60px;
    padding: 0;
  }
}
