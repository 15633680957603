.subscriptions-page {
  section {
    z-index: 3;
  }

  .bg-blurred-ellipse {
    background-image: url("../../assets/img/icons/ellipse.svg");
    // height: 600px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    // position: relative;
    z-index: -10;
    filter: blur(10px);
  }
}
