.ai-for-businesses-page {
  .ai-for-businesses-banner {
    background-image: url("../../assets/img/sample-screens/ai-for-businesses-7.png"); /* Replace with your image URL */
    width: 1200px; /* Adjust the width as needed */
    height: 650px; /* Adjust the height as needed */
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0 0 20px 20px #76bbda inset; /* Image Edges color */
    // box-shadow: 0 0 20px 20px rgb(84, 168, 199) inset; /* Hero bg color */
  }

  .interact-naturally-image {
    width: 500px;
    // height: 300px;
    border-radius: 30px;
  }
}
