.reports-page {
  // .one-stop-payment-image {
  //   max-height: 680px;
  // }

  .checklist-icon {
    width: 33px;
    height: 33px;
    padding: 0;
  }
}
