@use "../../shared-animation-styles" as *;

.link-with-icon--container {
  .link-container {
    padding: 5px 10px;
    align-items: "center";
    justify-content: "space-between";

    text-decoration: none;
    color: #000;

    &:hover,
    &.active {
      background-color: #7070700d;
    }

    border-bottom: 1px solid #eee;
  }

  .label-container {
    flex: 1;
    flex-direction: "row";
    align-items: "center";
  }

  .title-container {
    flex: 1;
    flex-direction: "column";
    align-items: "flex-start";
    justify-content: "center";
    margin-left: 15px;
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
    opacity: 0.6;
  }

  .iconRightContainer {
    padding: 0;
    margin-left: 10px;
  }

  .icon-left {
    align-self: center;
    img {
      width: 30px;
      height: 30px;

      .large {
        width: 50px;
        height: 50px;
      }
    }
  }

  &.icon-left--large {
    .icon-left {
      padding-left: 10px;
      margin-right: -10px;
      img {
        width: 50px;
        height: 50px;
        margin-left: -20px;
      }
    }
  }

  .icon-right {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .icon-subscript {
    position: absolute;
    margin-top: 12px;
    margin-left: -18px;
  }
}
