@use "../../shared-styles" as appMixins;

.home-page {
  .bg-switchapp-gradient {
    /* background: linear-gradient(to right, rgba(10, 39, 255, 0.85), rgba(83, 179, 249, 0.85)); */

    background-image: url("../../assets/img/hero-bg@2x.png");
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    // height: 80px;
    // @media (min-width: 768px) {
    //   height: 500px;
    // }
  }

  .switchapp-theme-card {
    // background: linear-gradient(to bottom, #112066, #0453ef);
    background: linear-gradient(to bottom, #203cbb, #1f5edd);
  }

  .globe-section {
    background-image: url("../../assets/img/globe-blue.png");
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .map-point {
    display: flex;
    align-items: center;
  }

  .switchapp-solutions-tabs {
    li {
      .nav-link {
        background-color: transparent !important;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0.5rem 1rem !important;
        border-left: 7px solid transparent !important;

        &.active {
          border-left: 7px solid #427ffa !important;

          h3 {
            color: #427ffa !important;
          }
        }
      }
    }
  }

  .tab-content {
    .tabs-testimonial-image {
      max-height: 400px;
      max-width: 100%;
    }
  }

  // .side-tabs {
  //   .nav-item {
  //     .navlink {
  //       background-color: transparent !important;
  //       color: #427FFA !important;

  //       //   h3 {
  //       //     color: #427FFA !important;
  //       //   }

  //       p {
  //         color: black;
  //       }
  //     }
  //   }
  // }

  .carousel-container {
    display: flex;
    flex-wrap: nowrap;

    @media only screen and (max-width: 600px) {
      @include appMixins.useThinScrollbar();
      overflow-x: auto;
    }

    .payment-provider-carousel-logo {
      height: 25px;
    }

    .payment-provider-item {
      min-width: fit-content;

      .status-indicator {
        display: inline-flex;
        padding: 5px;
        margin-left: 5px;
        border-radius: 50%;

        &.status-indicator-connected {
          background-color: green;
        }

        &.status-indicator-pending {
          background-color: orangered;
        }
      }
    }
  }
}
