.gradient-card {
  //   padding: 2rem;
  //   align-items: stretch;

  // background-color: linear-gradient(to bottom, blue, red);

  .gradient-card-icon {
    img {
      width: 80px;
    }
  }

  .gradient-card-link {
    a {
      color: black !important;
    }

    &::after {
      font-family: "Custom";
      content: "\e902";
      color: black !important;
      padding-left: 0.3rem;
      font-size: 0.35rem;
      font-weight: normal;
      vertical-align: 0.08rem;
      letter-spacing: -0.01rem;

      transition: margin-left 0.5s;
    }

    &:hover {
      &::after {
        margin-left: 5px;
      }
    }
  }
}
