.yellow-theme {
  // a,
  // a:hover,
  .dropdown-item:focus,
  .dropdown-item:hover,
  .dropdown-header,
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-menu-dark .dropdown-item.active,
  .dropdown-menu-dark .dropdown-item:active,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-link:focus,
  .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show > .nav-link,
  .page-item.active .page-link,
  .page-link:hover,
  .page-link:focus,
  .bg-dark.text-inverse a:not(.btn):not([class*="link-"]):hover,
  .text-inverse a:not(.btn):hover,
  [class*="offcanvas-"].text-inverse a:not(.btn):not([class*="link-"]):hover,
  .filter ul li a.active,
  .filter ul li a:hover,
  .icon-list.bullet-primary i,
  .icon-list.bullet-soft-primary i,
  .link-primary,
  .unordered-list.bullet-primary li:before,
  .link-primary:focus,
  .link-primary:hover,
  .post-meta li a:hover,
  .btn-outline-primary,
  .btn-check:active + .btn-outline-primary,
  .btn-check:checked + .btn-outline-primary,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show,
  .btn-outline-primary:active,
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled,
  .btn-soft-primary:hover,
  .btn-white.btn-play:hover,
  .btn-soft-primary,
  .navbar-dark.transparent.banner--stick
    .navbar-nav:not(.sm-collapsible)
    .nav-link
    .sub-arrow,
  .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
  .accordion-wrapper .card-header button,
  .accordion-wrapper .card-header button:before,
  .accordion-wrapper .card-header button:hover,
  .collapse-link,
  .collapse-link:hover,
  .breadcrumb-item a:hover,
  .dropdown-toggle::after,
  .plyr--full-ui input[type="range"],
  .progress-wrap:after,
  .banner--stick.transparent.navbar-dark .nav-link:focus,
  .banner--stick.transparent.navbar-dark .nav-link:hover,
  .banner--stick:not(.navbar-dark).navbar-dark .nav-link:focus,
  .banner--stick:not(.navbar-dark).navbar-dark .nav-link:hover,
  .external:hover {
    color: #fab758;
  }

  .link-dark:hover,
  .text-primary,
  .text-reset a:hover,
  a.text-reset:hover,
  .list-unstyled li a.active,
  .icon-list.bullet-bg i[class*="bullet-soft-primary"],
  .icon-list.bullet-bg i.bullet-white,
  .icon-list.bullet-white i,
  .sidebar nav .nav-link.active,
  .plyr__control--overlaid:focus,
  .plyr__control--overlaid:hover {
    color: #fab758 !important;
  }

  .form-control:focus {
    border-color: #fddbac;
  }

  .text-line.text-primary:before,
  .tooltip-inner,
  .icon-list.bullet-primary.bullet-bg i,
  .form-check-input:checked,
  .btn-primary,
  .btn-primary:hover,
  .btn-check:focus + .btn-primary,
  .btn-primary:focus,
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle,
  .btn-primary.disabled:not(.btn-circle):not(.btn-block),
  .btn-primary:disabled:not(.btn-circle):not(.btn-block),
  .btn-outline-primary:hover,
  .btn-check:active + .btn-outline-primary,
  .btn-check:checked + .btn-outline-primary,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show,
  .btn-outline-primary:active,
  .navbar-dark:not([class*="navbar-bg-"]).fixed .btn,
  .text-line:before {
    background-color: #fab758;
  }

  .bg-primary,
  .icon-list.bullet-bg i[class*="bullet-primary"] {
    background-color: #fab758 !important;
  }

  .btn-primary.btn-circle.ripple::after,
  .btn-primary.btn-circle.ripple::before,
  .plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__controls .plyr__control:hover,
  .plyr--video .plyr__controls .plyr__control[aria-expanded="true"] {
    background: #fab758;
  }

  .owl-carousel.nav-dark .item figure .item-link:hover,
  .owl-carousel.nav-dark .owl-nav button:hover {
    background: #fab758 !important;
  }

  .text-inverse a:not(.btn):hover,
  .form-check-input:checked,
  .btn-primary,
  .btn-primary:hover,
  .btn-check:focus + .btn-primary,
  .btn-primary:focus,
  .btn-primary.disabled:not(.btn-circle):not(.btn-block),
  .btn-primary:disabled:not(.btn-circle):not(.btn-block),
  .btn-outline-primary,
  .btn-outline-primary:hover,
  .btn-check:active + .btn-outline-primary,
  .btn-check:checked + .btn-outline-primary,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show,
  .btn-outline-primary:active,
  .navbar-dark:not([class*="navbar-bg-"]).fixed .btn,
  blockquote,
  .post-meta li a:hover,
  .hero-slider:before,
  .lg-outer .lg-item:after,
  .loader,
  .tp-loader.spinner {
    border-color: #fab758;
  }

  .border-primary {
    border-color: #fab758 !important;
  }

  /*.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  border-color: shade-color($primary, 12.5%),
}*/
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
  .bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #fab758;
  }

  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
  .bs-tooltip-end .tooltip-arrow::before,
  .wrapper.bg-primary.angled.lower-end:after,
  .wrapper.bg-primary.angled.upper-end:before {
    border-right-color: #fab758;
  }

  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
  .bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #fab758;
  }

  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
  .bs-tooltip-start .tooltip-arrow::before,
  .wrapper.bg-primary.angled.lower-start:after,
  .wrapper.bg-primary.angled.upper-start:before {
    border-left-color: #fab758;
  }

  .progress-list .progressbar.primary svg path:last-child,
  .progress-wrap svg.progress-circle path {
    stroke: #fab758;
  }

  .progress-list .progressbar.soft-primary svg path:last-child {
    stroke: #fcd49b;
  }

  .svg-fill,
  .text-primary .svg-fill {
    fill: #fcd49b;
  }

  ::-moz-selection {
    background: rgba(250, 183, 88, 0.7);
  }

  ::selection {
    background: rgba(250, 183, 88, 0.7);
  }

  .underline.primary:after,
  .underline:after {
    background: rgba(250, 183, 88, 0.12);
  }

  .underline-2.primary:after,
  .underline-2:after {
    background: #fab758;
  }

  .bg-soft-primary {
    background-color: #fffaf2 !important;
  }

  .bg-pale-primary {
    background-color: #fef3e4 !important;
  }

  .bg-gradient-primary {
    background: linear-gradient(180deg, #fffaf2, rgba(255, 255, 255, 0) 100%);
  }

  .bg-gradient-reverse-primary {
    background: linear-gradient(0deg, #fffaf2, rgba(255, 255, 255, 0) 100%);
  }

  .wrapper.bg-soft-primary.angled.lower-start:after,
  .wrapper.bg-soft-primary.angled.upper-start:before {
    border-left-color: #fffaf2;
  }

  .wrapper.bg-soft-primary.angled.lower-end:after,
  .wrapper.bg-soft-primary.angled.upper-end:before {
    border-right-color: #fffaf2;
  }

  .wrapper.bg-pale-primary.angled.lower-start:after,
  .wrapper.bg-pale-primary.angled.upper-start:before {
    border-left-color: #fef3e4;
  }

  .wrapper.bg-pale-primary.angled.lower-end:after,
  .wrapper.bg-pale-primary.angled.upper-end:before {
    border-right-color: #fef3e4;
  }

  .icon-list.bullet-soft-primary.bullet-bg i {
    background-color: #fef2e1;
  }

  .icon-list.bullet-bg i[class*="bullet-soft-primary"] {
    background-color: #fef2e1 !important;
  }

  form.dark-fields .form-control:focus {
    border-color: rgba(250, 183, 88, 0.5);
  }

  .btn-soft-primary {
    background-color: #fef3e4;
  }

  .alert-dismissible.alert-primary .btn-close {
    color: #e1a54f;
  }

  .alert-dismissible.alert-primary .btn-close:hover {
    color: #966e35;
  }

  .alert-primary {
    color: #af803e;
    background-color: #fff8ee;
    border-color: #fee9cd;
  }

  .alert-primary .alert-link {
    color: #c89246;
  }

  .owl-carousel.nav-dark .item figure .item-link,
  .owl-carousel.nav-dark .item figure .item-link.disabled,
  .owl-carousel.nav-dark .owl-nav button,
  .owl-carousel.nav-dark .owl-nav button.disabled {
    background: rgba(250, 183, 88, 0.9) !important;
  }

  .overlay.color span.bg {
    background: rgba(250, 183, 88, 0.7);
  }

  .itooltip-color.itooltip-inner,
  .itooltip-primary.itooltip-inner {
    background: rgba(250, 183, 88, 0.9);
  }

  .btn-soft-primary.btn-circle.ripple::after,
  .btn-soft-primary.btn-circle.ripple::before {
    background-color: #fffaf2;
  }

  .bg-dot.primary {
    background-image: radial-gradient(#fab758 2px, transparent 2.5px);
  }

  .bg-line.primary {
    background: repeating-linear-gradient(
      -55deg,
      rgba(255, 255, 255, 0) 0.8px,
      #fab758 1.6px,
      #fab758 3px,
      rgba(255, 255, 255, 0) 3.8px,
      rgba(255, 255, 255, 0) 10px
    );
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'><path fill='#fab758' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/></svg>");
  }

  .bg-soft-primary {
    background-color: #fff8ef !important;
  }

  .bg-gradient-primary {
    background: linear-gradient(
      180deg,
      #fff8ef,
      rgba(255, 255, 255, 0) 100%
    ) !important;
  }

  .bg-gradient-reverse-primary {
    background: linear-gradient(
      0deg,
      #fff8ef,
      rgba(255, 255, 255, 0) 100%
    ) !important;
  }

  .wrapper.bg-soft-primary.angled.lower-start:after,
  .wrapper.bg-soft-primary.angled.upper-start:before {
    border-left-color: #fff8ef !important;
  }

  .wrapper.bg-soft-primary.angled.lower-end:after,
  .wrapper.bg-soft-primary.angled.upper-end:before {
    border-right-color: #fff8ef !important;
  }

  .themed-svg {
    color: #fab758 !important;
    fill: currentColor !important;
  }
}
