.accordion {
  .card-body {
    padding: 0 1.5rem 0.85rem;
  }

  button {
    &.accordion-button {
      padding: 5px 5px;
      color: black !important;
      font-size: 22px;

      &::before {
        content: none;
      }

      .accordion-indicator {
        &::after {
          content: "-";
          font-family: "custom";
          font-size: 30px;
        }
      }

      &.collapsed {
        &::before {
          content: none;
        }

        .accordion-indicator {
          &::after {
            content: "+";

            font-family: "Custom";
            // font-size: 0.3rem;
            // font-weight: normal;
            // width: 1rem;
            // display: inline-block;
            // margin-top: 0.4rem;
            // color: #3f78e0;
            // position: absolute;
            // left: 1.3rem;
          }
        }
      }
    }
  }
}
