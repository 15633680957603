header {
  &.switchapp-header {
    background: #fff;
    box-shadow: 0 0.025rem 0.25rem rgba(0, 0, 0, 0.075);
    position: fixed;
    z-index: 3000;
    width: 100%;

    // transition: all 2s;

    &.homepage-header {
      background: linear-gradient(
        to right,
        #d4e2fb,
        rgba(83, 179, 249, 0.9)
      ) !important;

      /* filter: blur(2px);
          -webkit-filter: blur(1px); */
    }
  }
}
