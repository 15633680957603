.payment-links-page {
  .checklist-icon {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .bank-card-icon {
    width: 40px;
    padding: 0;
  }

  .border-pale-blue {
    border-color: #0453ef38 !important;
  }

  @media (min-width: 576px) {
    .md-no-top-border {
      .border-pale-blue {
        border-color: transparent !important;
      }
    }
  }
}
